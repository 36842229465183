import * as React from 'react'
import { ReactNode } from 'react'

import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { styled } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { isNil } from 'ramda'
import { useBoolean } from 'usehooks-ts'

import useAnalytics from 'src/lib/hooks/useAnalytics'

interface StyleAccordionProps extends AccordionProps {
  borderstyle?: string
  customstyle?: string
}

const StyledAccordion = styled((props: StyleAccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    {...props}
    className={`${
      !isNil(props.borderstyle)
        ? props.borderstyle
        : 'overflow-hidden !rounded-md !border-[1px] !border-gray-200'
    }`}
  />
))(() => ({}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ChevronRightIcon className={'h-4 w-4 text-gray-900'} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#f9fafb',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({}))

interface Props {
  expanded?: boolean
  title?: React.ReactElement | string
  summary?: React.ReactElement | string
  TransitionProps?: object
  borderstyle?: string
  customstyle?: string
  titleDataTestId?: string
  removeDetailsPadding?: boolean
  GATrackerCategory?: string
  GATrackerEventName?: string
  children?: ReactNode
  onExpand?: () => void
  onCollapse?: () => void
}

export const Accordion: React.FC<Props> = ({
  expanded = false,
  children,
  title,
  summary,
  TransitionProps,
  borderstyle,
  customstyle,
  titleDataTestId,
  removeDetailsPadding = false,
  GATrackerCategory,
  GATrackerEventName,
  onExpand = () => {},
  onCollapse = () => {},
  ...rest
}) => {
  const isExpanded = useBoolean(expanded)
  const { trackEvent } = useAnalytics()
  const handleMainClick = () => {
    if (!GATrackerCategory) return
    trackEvent(
      GATrackerCategory,
      GATrackerEventName ? GATrackerEventName : 'click accordion',
    )
  }

  React.useEffect(() => {
    if (isExpanded.value) {
      onExpand()
    } else {
      onCollapse()
    }
  }, [isExpanded.value])

  return (
    <StyledAccordion
      expanded={isExpanded.value}
      onChange={isExpanded.toggle}
      TransitionProps={TransitionProps}
      borderstyle={borderstyle}
      customstyle={customstyle}
      onClick={handleMainClick}
      {...rest}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        className={customstyle}
      >
        <span
          className={
            'text-md w-full text-base font-medium leading-6 text-gray-900'
          }
          data-testid={titleDataTestId}
        >
          {title}
        </span>
        {summary}
      </AccordionSummary>
      <AccordionDetails
        className={`border-t-[1px] border-gray-200 bg-white ${
          removeDetailsPadding ? 'p-0' : 'no'
        }`}
      >
        {isExpanded.value && <>{children}</>}
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default Accordion
